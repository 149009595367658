import React from "react";
import Hero from "../components/commonComponent/hero";
import Layout from "../components/layout.component";
import Experience from "../components/SpecialEducationPage/experience";
import EducationalGoal from "../components/SpecialEducationPage/educationalGoal";
import Outcome from "../components/commonComponent/outcome";
import Enrollment from "../components/SpecialEducationPage/Enrollment";
import Curriculum from "../components/commonComponent/curriculum";
import ParentFeedback from "../components/SpecialEducationPage/parentFeedback";
import SEO from "../components/seo";


const Specialeducation = () => {
	return (
		<Layout>
                  <Hero slug="Special-Education" />
            <Experience />
            <EducationalGoal />
            <Outcome slug="Special-Education" />
                  <ParentFeedback />
                  <Curriculum slug= "SpecialEducation" />
            <Enrollment />
		</Layout>
	)
}
export const Head = () => {
    const schemaOrgJSONLD = [
        {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": "Online Classes for Kids",
            "image": "https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/5c42c38121314a0cb48b2524f64ad570.svg",
            "description": "Get your kid ready for the future through the most efficient online classes for kids in India.",
            "brand": {
                "@type": "Brand",
                "name": "The Real School"
            },
            "sku": "TRS01",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "bestRating": "5",
                "worstRating": "3",
                "ratingCount": "101189"
            },
            "review": [
                {
                    "@type": "Review",
                    "name": "Online Classes for Kids",
                    "reviewBody": "Practical brilliance,Real School shapes young minds remarkably well.",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "4.9",
                        "bestRating": "5",
                        "worstRating": "2"
                    },
                    "datePublished": "2021-07-11",
                    "author": {
                        "@type": "Person",
                        "name": "Sakshi"
                    },
                    "publisher": {
                        "@type": "Organization",
                        "name": "The Real School"
                    }
                },
                {
                    "@type": "Review",
                    "name": "Online Classes for Kids",
                    "reviewBody": "Real School's online platform fosters bright futures, highly recommended!",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "4.9",
                        "bestRating": "5",
                        "worstRating": "1"
                    },
                    "datePublished": "2021-08-07",
                    "author": {
                        "@type": "Person",
                        "name": "Varsha"
                    },
                    "publisher": {
                        "@type": "Organization",
                        "name": "The Real School"
                    }
                },
                {
                    "@type": "Review",
                    "name": "Online Classes for Kids",
                    "reviewBody": "A+ for Real School's projected approach to developing knowledge.",
                    "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "4.9",
                        "bestRating": "5",
                        "worstRating": "3"
                    },
                    "datePublished": "2022-11-22",
                    "author": {
                        "@type": "Person",
                        "name": "Harkeerat's Mother"
                    },
                    "publisher": {
                        "@type": "Organization",
                        "name": "The Real School"
                    }
                }
            ]
        }
    ]
      return (
            <SEO description="This is the best platform for the kid's holistic development and growth. It offers the best online classes for kids and the best online schools. Join online classes for kids India to learn effectively and interactively."
                  title="The Real School | Let's Stable the Kid's Future with Best Online Schools"
                  keywords="activity classes for kids learning activities for kids educational activities for kids"
                  children={
                        <script type="application/ld+json">
                             {JSON.stringify(schemaOrgJSONLD)}
                        </script>
                    }
            />
      )
}

export default Specialeducation
