import React, { useState } from "react";
import styled from "styled-components";
import Flickity from "react-flickity-component";
import VideoPopup from "../commonComponent/videoPopup";
import { useCurriculumData } from "../../hooks/program-curiculum";


const Curriculum = (props) => {
    const [showVideo, setShowVideo] = useState(false);
    const [videoDetails, setVideoDetails] = useState({
        url: "",
        name: "",
    });

   
    const allData = useCurriculumData()
    let curriculumData= allData.filter((data)=>data.slug === props.slug)[0]
	const options = {
		"groupCells": true
	}
    return (
        <CarouselSectionStyled>
            <CarouselSectionWrapperStyled>
                <div>
                    <p id="carousel_heading_1" dangerouslySetInnerHTML={{ __html: curriculumData?.heading }} />

                </div>

                <CarouselWrapperStyled>
                    <Flickity
                        options={options}
                    >
                        {curriculumData?.curriculum_carousel?.map((studies) => {
                            return (
                                <div className="carousel-cell" key={studies.id}>
                                    <div
                                        className="carousel_card"
                                        aria-hidden="true"
                                    >
                                        <div className="carousel_image">
                                            <img src={studies?.img?.url} alt={studies?.img?.name} />

                                        </div>

                                        <div className="carousel_head">
                                            <h1>{studies?.heading}</h1>
                                        </div>
                                        <div className="carousel_details">
                                            {studies?.content.map((content) => (
                                                <p className="detail" dangerouslySetInnerHTML={{ __html: content?.content }} />
                                            )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Flickity>
                </CarouselWrapperStyled>
            </CarouselSectionWrapperStyled>
            {showVideo && videoDetails.url !== "" && (
                <VideoPopup setShowVideo={setShowVideo} link={videoDetails.url} name={videoDetails.name} />
            )}
        </CarouselSectionStyled>
    );
};

const CarouselSectionStyled = styled.section`
display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    align-items: center;
	padding:10px;
	@media(max-width: 500px){
		padding: 50px 10px;
    gap: 20px;
	height: 500px;
	}
`;
const CarouselSectionWrapperStyled = styled.div`
	padding-bottom: 5em;
	height: 100%;
	width:100%;
	align-items: center;
	padding: 0 7.5em;
	column-gap: 6.5em;
	#carousel_heading_1 {
		margin-top: 1rem;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #2a264c;
		margin-bottom: 0.75rem;
	}
	@media (max-width: 1300px) {
		column-gap: 2em;
		padding: 0 5.5em;
	}
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
		row-gap: 2em;
		#carousel_heading_1 {
			text-align: center;
		}
	}

	@media (max-width: 750px) {
		padding: 0;
	}
	@media (max-width: 599px) {
		row-gap: 1.2em;
		#carousel_heading_1 {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}

	@media (max-width: 359px) {
		#carousel_heading_1 {
			font-size: 1.3rem;
		}
	}
`;


const CarouselWrapperStyled = styled.div`
	background:#FDF3F3;
	.carousel-cell {
		height: 25em;
		margin: 1em;
	}
	.carousel_card {
		box-shadow: 6px 6px 28px -14px rgba(81, 46, 136, 0.2);
		background: #ffffff;
		border-radius: 16px;
		padding: 0.5em;
		display: flex;
		flex-direction: column;
		gap: 0.75em;
		min-width: 16.4375em;
		max-width: 16.4375em;
		margin-right: 1.5em;
		marigin-bottom:  10px;
		position: relative !important;
	}
	.carousel_image {
		width: 100%;
		display:flex;
		alig-items:center;
	}
	.carousel_image img{
		width: 100%;
		margin:0 40%;

	}
	.carousel_head h1{
        font-weight: 500;
        font-size: 20px;
        line-height: 113.4%;
        text-align: center;
        color: #2A264C;
    }
	.carousel_details {
        margin: 25px 0 20px;
		display: flex;
		flex-direction: column;
		gap: 1em;
		align-items:center;
	}
	.fa-play {
		font-size: 3rem;
		color: #ffffff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
	}
	.carousel_detail_name {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1.25rem;
		line-height: 24px;
		color: #113a68;
	}
	.carousel_detail_desgn {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #8295ab;
	}

	.flickity-prev-next-button.previous {
		/* display: none; */
		width: 64px;
		height: 64px;
		left: -80px;
		top: 47%;
		background: #f9ce4b;
		box-shadow: 0px 0px 25px rgba(252, 153, 124, 0.25);
		color: #000;
	}
	.flickity-prev-next-button.next {
		width: 64px;
		height: 64px;
		right: -80px;
		top: 47%;
		background: #f9ce4b;
		box-shadow: 0px 0px 25px rgba(252, 153, 124, 0.25);
		color: #000;
	}
	.flickity-button:active {
		opacity: 1;
	}

	@media (max-width: 1300px) {
		.flickity-prev-next-button.next {
			/* right: 15px; */
		}
		.flickity-prev-next-button.previous {
			/* left: 15px; */
		}
	}
	@media (max-width: 1100px) {
		.carousel_card {
			margin-right: 1em;
		}
	}
	@media (max-width: 750px) {
		padding: 1em;
		height: 400px;
		.carousel_card {
			margin-right: -1em;
		}
		.flickity-enabled{
			height:400px
		}
		.flickity-viewport{
			height:400px !important;
		}
	}

	@media (max-width: 500px) {
		.carousel_card {
			min-width: 12.5em;
			max-width: 12.5em;
			height: 350px;
			gap:0.5em;
		}
		.carousel_detail_name {
			font-size: 1rem;
			line-height: 19px;
		}
		.carousel_details {
			margin: 0pc 0 20px;
			gap: 0.5em;
		}
		.carousel_image img {
			height: 50%;
			margin: 11px 70px
		}
		.flickity-prev-next-button.next {
			height: 40px;
			width: 40px;
			top: 50%;
			right: 0px;
		}
		.flickity-prev-next-button.previous {
			height: 40px;
			width: 40px;
			top: 50%;
			left: 0px;
			/* display: none; */
			/* width: 64px;
			height: 64px;
			left: -80px;
			top: 47%;
			background: #113a68;
			box-shadow: 0px 0px 25px rgba(252, 153, 124, 0.25);
			color: #fff0eb; */
		}
		.carousel-cell {
			height:100%;
		}
	}
`;


export default Curriculum;
