import React from "react";
import styled from "styled-components";
import { usePresonalizedData } from "../../hooks/program-personalized-class";

const Experience = () => {
	const data = usePresonalizedData()
let experienceData 
	data.map((ele) => {
		if (ele.slug === "Special-Education-Personalized-class") {
			experienceData = ele
		}
	})
	console.log(data);
	return (
		<EducationalGoalStyled>
			<EducationalGoalWrapperStyled>
				<h3 key={`heading`} id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: experienceData?.heading }} />

				<EducationalGoalCardWrapperStyled>
					{experienceData?.Personal_Classroom?.map((list, index) => {
						return (
							<EducationalGoalCardStyled key={list.id}>
								<img src={list?.image?.url} alt="" />

								<span className="evaluate_profile_name">{list?.img_description}</span>
								{/* <div className="click_arrow">
                            <Link to={list.redirect_to}>
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    size="1x"
                                    className="fa-angle-right"
                                />
                            </Link>
                        </div> */}
							</EducationalGoalCardStyled>
						);
					})}
				</EducationalGoalCardWrapperStyled>
			</EducationalGoalWrapperStyled>
		</EducationalGoalStyled>
	);
};

const EducationalGoalStyled = styled.div`
	margin-top: 2rem;
	padding: 0 1em;
`;

const EducationalGoalWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 4em;
	padding-bottom: 7em;
	& h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2a264c;
	}

	@media (max-width: 500px) {
		gap: 1em;
		& h3 {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}
`;

const EducationalGoalCardWrapperStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 2em;
	flex-wrap: wrap;
	width: 100%;
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 6em
	}
	@media (max-width: 500px) {
		margin-top: 1em;
	}
	@media (max-width: 317px) {
		gap: 0.4em;
	}
`;

const EducationalGoalCardStyled = styled.div`
	min-width: 20em;
	max-width: 20em;
	min-height: 10em;
	max-height: 10em;
	padding: 1em 3.34375em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1em;
	background: #ffffff;
	.evaluate_profile_name {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #2A264C;
		max-width: 12rem;
	}

	.click_arrow {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background: #f1f1f1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.fa-angle-right {
		color: #113a68;
		font-size: 1.2rem;
		height: 1em;
	}
	@media (max-width: 768px) {
		& img {
			min-width: 18em;
			max-width: 18em;
			min-height: 12em;
			max-height: 12em;
		}
		.evaluate_profile_name {
			
		}
	}
	@media (max-width: 500px) {
		min-width: 9em;
		max-width: 9em;
		padding: 1em;
		gap: 0.5em;
		box-shadow: none;
		& img {
			height: 50px;
			width: 50px;
		}

		.evaluate_profile_name {
			font-size: 0.9rem;
			line-height: 19px;
		}
		/* &:nth-child(3) {
			min-width: 100%;
			max-width: 100%;
			flex-direction: row;
		}
		&:nth-child(3) .evaluate_profile_name {
			text-align: left;
		} */
	}

	@media (max-width: 353px) {
		min-width: 7em;
		max-width: 7em;

		.evaluate_profile_name {
			font-size: 0.7rem;
		}
	}
	@media (max-width: 317px) {
		& img {
			height: 40px;
			width: 40px;
		}
	}
`;

export default Experience;
