import React from "react";
import styled from "styled-components";
import { useEnrollmentData } from "../../hooks/enrollmentDesign";
import { Link } from "gatsby";
const Enrollment  = () => {
    const data = useEnrollmentData()
    let enrollmentData
    data.map((ele) => {
        if (ele.slug === "Special-Enrollment-Process") {
            enrollmentData = ele
        }
    })
    
    return (
        <EnrollmentOuterWrapperStyled>
            <p key={`heading`} id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: enrollmentData?.heading }} />
            <EnrollmentInnerWrapperStyled>
                <EnrollmentProcessWrapperStyled>
                    {enrollmentData?.features?.map((list) => {
                        return (
                            <div className="each-process">
                                <p className="process-name">{list?.UpperText}</p>
                            </div>
                        )
                    })}
                </EnrollmentProcessWrapperStyled>
                <p className="sub-heading">{ enrollmentData?.sub_heading}</p>
                <Link to={`/form/?formName=${enrollmentData?.Button.redirect_to}`}>
                    <button className="enrollment-button">{ enrollmentData?.Button?.heading}</button>
                </Link>
            </EnrollmentInnerWrapperStyled>
        </EnrollmentOuterWrapperStyled>
    )
}


const EnrollmentOuterWrapperStyled = styled.section`
    display: flex;
    align-items: center;
	justify-content: center;
	flex-direction: column;
    font-family: "Inter";
    margin-top: 4rem;
    padding: 2rem;
    #custom_counselling_heading {
        font-size: 2.5rem;
        color: #2A264C;
        font-weight: 900;
    }

    @media(max-width: 550px){
        #custom_counselling_heading {
            font-size: 1.5rem;
        }
    }

`;

const EnrollmentInnerWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: "Inter";
    margin: 50px 0;
    background: #FFFFFF;
    padding: 3rem 4.5rem;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .sub-heading {
        margin: 20px 0;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #2A264C;
    }

    .enrollment-button {
        background: #F9CE4B;
        border-radius: 5px;
        color: #2A264C;
        font-weight: 700;
font-size: 16px;
        padding: 0.75rem 1rem;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-items: center;
    }

    @media(max-width: 1100px){
        padding: 2.5rem;
    }
    @media(max-width: 768px){
        padding: 1.5rem;
        width: 90%;
    }
    @media(max-width: 575px){
        .enrollment-button {
            background: #F9CE4B;
            color: #2A264C;
            padding: 0.75rem 1.5rem;
        }
        padding: 0.75rem 1rem;
    }

`;

const EnrollmentProcessWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    font-family: "Inter";

    .each-process {
        width: 300px;
        background-color: #FFE6DF;
        border-radius: 5px;
        padding: 1rem;
    }
    .process-name {
        color: #2A264C;
        font-size: 15px;
        font-weight: 600;
    }

    @media(max-width: 1100px){
        .each-process {
            width: 225px;
        }
    }
    @media(max-width: 768px){
        .each-process {
            width: 200px;
        }
    }
    @media(max-width:575px){
        gap: 0.5em;
        .each-process {
            width: 100%;
        }
    }
`;

export default Enrollment
