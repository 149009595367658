import { useStaticQuery, graphql } from "gatsby";

export const usePresonalizedData = () => {
    const { allStrapiProgramPersonalizedClass } = useStaticQuery(
        graphql`
      query personalizedQuery {
        allStrapiProgramPersonalizedClass {
          nodes{
            heading
            slug
            Personal_Classroom{
                img_description
                image{
                    url
                }
            }
          }
        }
      }
    `
    );
    return allStrapiProgramPersonalizedClass.nodes;
};
