import { useStaticQuery, graphql } from "gatsby";

export const useCurriculumData = () => {
  const { allStrapiProgramCuriculum } = useStaticQuery(
    graphql`
      query curiculumQuery {
        allStrapiProgramCuriculum {
          nodes{
            heading
            slug
            curriculum_carousel{
              heading
              content{
                content
              }
              img{
               url
              }
            }
          }
        }
      }
    `
  );
  return allStrapiProgramCuriculum.nodes;
};
